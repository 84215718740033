import * as React from 'react'
import { Button, Space } from 'capricorn-ui'
import { FormattedMessage } from 'react-intl'
import { ROUTE } from 'constants/routes'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useStore } from 'src/store'
import ArrowSvg from 'assets/icons/arrow-left.svg'
import Link from 'containers/Link'
import styled from 'astroturf/react'

interface Props {
    children?: React.ReactNode
    white?: boolean
    rightContent?: React.ReactNode
    leftContent?: React.ReactNode
    onBack?: () => void
    noBack?: boolean
    showCompleteVerificationIfNeeded?: boolean
    titleAlignLeft?: boolean
    noSlickHeader?: boolean
}

const MobilePageHeader: React.FC<Props> = ({
    noBack,
    showCompleteVerificationIfNeeded,
    children,
    leftContent,
    onBack,
    rightContent,
    titleAlignLeft,
    noSlickHeader,
    ...props
}) => {
    const { auth } = useStore()
    const navigate = useNavigate()
    const [scrollValue, setScrollValue] = useState(0)

    useEffect(() => {
        const onScroll = (e) => {
            setScrollValue(e.target.scrollTop)
        }

        document.querySelector('#page-content')?.addEventListener('scroll', onScroll)

        return () => window.removeEventListener('scroll', onScroll)
    }, [])

    return (
        <>
            <SlickHeader
                style={{
                    padding: `calc(env(safe-area-inset-top) + ${
                        scrollValue > 30 ? 10 : scrollValue / 3
                    }px - 4px) 0 calc(${scrollValue > 30 ? 15 : scrollValue / 2}px + 4px)`,
                }}
                hide={noSlickHeader}
            >
                <div
                    style={{
                        height: scrollValue > 30 ? 16 : scrollValue / 1.875,
                        opacity: scrollValue > 60 ? 1 : (scrollValue - 30) / 60,
                    }}
                >
                    {children}
                </div>
            </SlickHeader>
            <Styled
                spaceBottom={
                    children && showCompleteVerificationIfNeeded && auth.user?.verificationStatus !== 'verified'
                }
                align="center"
                justify="space-between"
                {...props}
            >
                {!noBack ? (
                    <LinkStyled onClick={onBack ? onBack : () => navigate(-1)}>
                        <ArrowSvg width={10} />
                        {leftContent || <FormattedMessage defaultMessage="Back" />}
                    </LinkStyled>
                ) : leftContent || showCompleteVerificationIfNeeded ? (
                    <LeftContent>
                        {showCompleteVerificationIfNeeded && auth.user?.verificationStatus !== 'verified' ? (
                            <Button size="s" data-cy="pass-verify" onClick={() => navigate(ROUTE.VERIFICATION)}>
                                {auth.user?.verificationStatus === 'none' ? (
                                    <FormattedMessage defaultMessage="Complete Verification" />
                                ) : (
                                    <FormattedMessage defaultMessage="Verification on check" />
                                )}
                            </Button>
                        ) : (
                            leftContent
                        )}
                    </LeftContent>
                ) : (
                    <div />
                )}
                <Title
                    left={titleAlignLeft}
                    leftContentExist={showCompleteVerificationIfNeeded && auth.user?.verificationStatus !== 'verified'}
                >
                    {children || <>&nbsp;</>}
                </Title>
                {rightContent ? <RightContent>{rightContent}</RightContent> : null}
            </Styled>
        </>
    )
}

const SlickHeader = styled.div<{ hide?: boolean }>`
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 9;
    background: var(--bg-color-1);
    text-align: center;

    @media (max-width: 600px) {
        font-weight: 600 !important;
        font-size: 16px !important;

        * {
            font-weight: 600 !important;
            font-size: 16px !important;
        }
    }

    &.hide {
        display: none;
    }
`

const Title = styled.div<{ left?: boolean; leftContentExist?: boolean }>`
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;

    &.left {
        text-align: left;

        &.leftContentExist {
            top: 50px;
        }
    }
`

const LeftContent = styled.div`
    position: relative;
    z-index: 1;
`

const LinkStyled = styled(Link)`
    position: relative;
    display: flex;
    align-items: center;
    font-weight: 400;
    z-index: 1;
`

const RightContent = styled.div`
    position: relative;
    z-index: 1;
`

const Styled = styled(Space)<{ white?: boolean; spaceBottom?: boolean }>`
    position: relative;
    width: calc(100% - 30px);
    font-weight: 600;
    font-size: 15px;
    z-index: 8;

    &.white {
        color: #fff;

        ${LinkStyled} {
            color: #fff;
        }
    }

    &.spaceBottom {
        margin-bottom: 55px;
    }
`

export default MobilePageHeader
